import './Home_Main.css'
import icon from './icon.jpeg'

export default function Home_Main() {
    return (
        <>
        <section class="animated-background">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
        </section>

        <a id="profilePicture" href="#">
        <img src={icon} alt="Icon" />
        </a>

        <div id="userName">
        Blake Bullard
        </div>

        <div id="links">
            <a class="link" href="https://www.youtube.com/@Blake_Bullard" target="_blank" rel="noreferrer">
                <i class="fab fa-youtube">&nbsp;</i>Youtube
            </a>
            <a class="link" href="https://www.instagram.com/blake.bullard.06" target="_blank" rel="noreferrer">
                <i class="fab fa-instagram">&nbsp;</i>Instagram
            </a>
            <a class="link" href="https://twitter.com/BlakeBullard_" target="_blank" rel="noreferrer">
                <i class="fab fa-twitter">&nbsp;</i>Twitter
            </a>
        </div>
        </>
    );
}